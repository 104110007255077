import React, {useState} from "react";
import {Button, Input, ModalBody, ModalFooter, ModalHeader, Spinner} from "reactstrap";
import {useAppContext} from "../../hooks";
import {postJsonAxiosService} from "../../services/callAxios";
import {toastService} from "../../services/toast.service";

const ClariModal = (props) => {
    const {user} = useAppContext();
    const {provider, toggle} = props;
    const [apikey, setApikey] = useState("");
    const [errorApikey, setErrorApikey] = useState(false);
    const [password, setPassword] = useState("");
    const [errorPassword, setErrorPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmit = async () => {

        if (!apikey) {
            setErrorApikey(true);
        }
        let provider = {
            accessToken: password,
            providerId: apikey,
            provider: "Clari",
            service: "Web Conferencing",
        }
        try {
            setLoading(true);
            let response = await postJsonAxiosService("clari/webhook/validate", {provider: provider});
            setLoading(false);
            console.log (response);
            if (response) {
                if (!user.providers || user.providers.length === 0) {
                    user.providers = [];
                }
                // user.providers.push(provider);
                toastService.info("Clari Integrated");
                window.location.reload();
            }
        } catch (e) {
            setLoading(false);
            toastService.error("Error while updating integration");
        }
    };

    return (
        <div style={{padding: 20}}>
            <ModalHeader toggle={props.toggle}
                         style={{fontSize: 24, fontWeight: 600, border: "none"}}>
                <span>Add Clari Integration</span>
            </ModalHeader>
            <ModalBody>
                <div>
                    <div className="snippet-desc" style={{fontSize: 14, color: "#202124"}}>
                        <label>Api Key</label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Enter API Key"
                               value={apikey}
                               onChange={(e) => {
                                   setApikey(e.target.value);
                                   setErrorApikey(false);
                               }}
                        />
                        {errorApikey ? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter API Key
                            </div>
                        ) : null}
                    </div>
                    <div className="snippet-desc" style={{fontSize: 14, color: "#202124"}}>
                        <label>Password</label>
                        <Input style={{fontSize: 14, color: "#202124"}}
                               placeholder="Enter Password"
                               value={password}
                               onChange={(e) => {
                                   setPassword(e.target.value);
                                   setErrorPassword(false);
                               }}
                        />
                        {errorPassword ? (
                            <div className="snippet-desc-error" style={{color: "red"}}>
                                Please enter Password
                            </div>
                        ) : null}
                    </div>
                </div>

            </ModalBody>
            <ModalFooter style={{border: "none", padding: "0px 0px 10px 30px"}}>
                <Button outline color="primary" onClick={props.toggle}>
                    Cancel
                </Button>{" "}
                <Button color="primary" onClick={handleSubmit} disabled={loading}>
                    {!loading ? "Authorize" : "Authorizing"}
                    {loading && <Spinner size="sm" type="grow" color="light" />}
                </Button>{" "}
            </ModalFooter>
        </div>
    )
};

export default ClariModal;