import {gql} from "apollo-boost";

const customerFragments = {
    customer: gql`
    fragment CustomerInfo on Customer {
      id
      name
      type
      industry_code
      industry
      address
      zipCode
      state
      country
      email
      phone
      description
      sic
      tickerSymbol
      naicsCode
      status,
      skipSystemTopics,
      hideAllMeetings,
      durationProcessTopics,
      languageModelText,
      competitors,
      dealInsights {
        noActivity,
        ghosted,
        topicMentions {
          pricing,
          bant,
          nextSteps,
          competitors
        },
        overdue,
        notEnoughContacts	{
          contacts,
          days
        },
        stalledInStage{
          stageName ,
          days ,
        },
        stakeHolders {
          title 
          days
        }
      }
      providers  {  id, provider, providerId, accountId, service ,createdAt, updatedAt, stages, leadSources, callDisposition {name index}, pipeline {pipelineId, pipelineName, stages}, integration{key, value}},
      trackers { name, keywords, topics, whenSaidBy, callScope, trackIn, teams, relatedForms, lastUpdatedBy { firstName, lastName }, lastUpdatedDate }
      topics { name, topics, whenSaidBy, callScope, trackIn, teams, enabled }
      allowRecallZoom
    }
  `,
};

const partialFragments = {
    partialMeeting: gql`
  fragment MeetingInfo on Meeting {
     providerMeetingId
     providerUUID
     title
     date
     host
     hostName
     user {firstName lastName _id}
     status
     duration
     recordingStatus
     owner
     callType
     transcriptionLanguage
     callDisposition
     manager {name email id}
     listenLaterUsers {
          firstName
          lastName
          _id
      }
     relation {opportunityId stageName contactId leadId accountId}
     highLights { name text topics subTopics trackers title }
     transcriptHighlights { name text topics subTopics trackers}
     transcripts { id name text topics subTopics trackers}
     transcriptsSelected { name start end text topics }
     analytics {
        longest_monologue_snippet {length,end,start}
        longest_story_snippet{length,end,start}
        patience
        question_rate_host  
        question_rate_prospect
        number_host_questions 
        number_prospect_questions
        interactivity  
        tl_ratio
        sentiment
        lead_score { name score explanation }
        subtopic_count { name duration percentage mentions }
        tracker_count { name duration percentage mentions }
        topic_count { name duration percentage mentions }
     }                 
    }
`,
};


const fragments = {
    meeting: gql`
   fragment Meeting on Meeting {
    users { firstName lastName }
    teams { name }   
    description
    sources { id  size thumb  fileType url playURL}
    subtitle
    thumb
    callType
    transcriptionLanguage
    callDisposition
    manager {name email id}
    listenLaterUsers {
          firstName
          lastName
          _id
      } 
    customerId
    title
    date
    host
    hostName
    user {firstName lastName _id}
    status
    id
    recordingStatus
    owner
    relation {opportunityId stageName contactId leadId accountId}
    provider
    providerMeetingId
    providerUUID
    duration
    highLights { name text topics subTopics trackers title }
    transcriptHighlights { name text topics subTopics trackers title }
    transcriptsSelected { name start end text topics }
    transcripts { id name text topics subTopics trackers }
    analytics {
      longest_monologue_snippet {length,end,start}
      longest_story_snippet{length,end,start}
      patience
      question_rate_host  
      question_rate_prospect
      number_host_questions 
      number_prospect_questions
      interactivity
      tl_ratio
      sentiment
      lead_score { name score explanation }
      subtopic_count { name duration percentage mentions }
      tracker_count { name duration percentage mentions }
      topic_count { name duration percentage mentions }
      talktrack { userid duration percentage }
    }                 
    attendees { email  name role status phone }
    account {owner  {id, email, firstName, lastName}
    name
    type
    industry_code
    industry
      }
   }
  `,

    role: gql`
    fragment Role on Role {
      id
     name
     customerId
    license,
		administration  {
			integrations,
			organization ,
			manageRoles,
      manageTeams,
			manageBilling,
			manageUsers,
		},
		recordings  {
			shareRecording ,
			editRecording ,
			deleteRecording,
			privateRecording,
			downloadRecording
		},
		playlist  {
			sharePlaylist 
		},
		tools  {
			topics,
      manageTrackers,
			manageVocabulary,
			manageDealInsights 
		},
		coaching  {
			scorecards,
      addScoreCards
		}
    }
  `,
    team: gql`
    fragment Team on Team {
      id,
      name,
      customerId,
      access,
      managerId,
      additionalManagerIds,
      users,
      userDetails {
          id
          firstName
          lastName
          email
      }
      manager {
            id
            firstName
            lastName
            email
      }
      additionalManagers {
            id
            firstName
            lastName
            email
      }
    }
    `,
    email: gql`
    fragment Email on Email {
      emailThread {
        from
        to
        cc
        subject
        body
        stageName
        date
        topics
      }
      owner {
        email
        firstName
        lastName
      }
    }`,
};


const meetingEmailFragments = {
    MeetingsEmailResult : gql`
    fragment MeetingsEmailResult on MeetingsEmailResult {
          Meeting {...Meeting},
          Email {...Email}
    }
    ${fragments.email}, 
    ${fragments.meeting}`

};

const userFragments = {
    user: gql`
     fragment User on User {
      id,firstName,lastName,
      designation,
      phone,
      license,
      bioMetricUrl,
      role{...Role},
      teams{...Team},
      customer{...CustomerInfo}, email,photoUrl
      providers { id, provider,  service, accessToken, createdAt, updatedAt, stages, leadSources, callDisposition {name index}, pipeline {pipelineId, pipelineName, stages}, integration {key, value} recordingBot},
      login {  id,  userName,  password,  loginAttempts,  lockUntil,lastLogin}  
      status 
      syncOn
      states {
        id
        name
        userId
        type
        data
        createdAt
      }
      createdAt, 
      updatedAt
      emailVerificationToken
      trialStarted
      trialEnded
      subscriptionId
      transcriptLanguage
      allowNotetaker
      showAsk
      licenseConfig { id, licenseType, transcriptionMax, numberOfUser, enterpriseZoom, bot, upload, dialer, chromeExtension, allowedVideoStorage, allowedAudioStorage }
     } 
    ${customerFragments.customer} ,
    ${fragments.team}, 
    ${fragments.role}  
  `
};
const dealFragments = {
    deal: gql`
    fragment OpportunityResult on OpportunityResult {
        id
        dealName,
        representativeEmail,
        representative,
        stage,
        pipelineId,
        pipelineName,
        currencyType,
        startedAt,
        accountName,
        amount,
        closingDate,
        isClosed,
        isWon,
        meetingCount,
        pricingCount{
          competitors,
          nextSteps,
          pricing,
          bant
        }
        contacts { contactName, contactEmail, contactPhone, contactRole },
        lastMeetingContact { contactEmail contactName contactPhone contactRole id }
        lastMeetingDate,
        lastEmailContact{ contactEmail contactName contactPhone contactRole id },
        lastEmailDate,
        opportunityType
        dealSummary { id summary updatedAt version }
    }
   `,
    dealWithConfig: gql`
  fragment OpportunityResultWithConfig on OpportunityResultWithConfig {
      counts {
          _id
          sumAmount
          count
      }
    opportunityResults {
      id
      dealName,
      representativeEmail,
      representative,
      pipelineId,
      pipelineName,
      currencyType,
      stage,
      startedAt,
      accountName,
      amount,
      closingDate,
      isClosed,
      isWon,
      meetingCount,
      pricingCount{
        competitors,
        nextSteps,
        pricing,
        bant
      },
      contacts { contactName, contactEmail, contactPhone, contactRole },
      lastMeetingContact { contactEmail contactName contactPhone contactRole id}
      lastMeetingDate,
      lastEmailContact{ contactEmail contactName contactPhone contactRole id },
      lastEmailDate,
      opportunityType
    }
    totalCount
  }
 `,
}

const accountFragment = {
    account: gql`
    fragment AccountResult on AccountResult {
      providerAccountId
      accountName
      createdAt
      updatedAt
      reps
      amount {type, amount}
      dealAmount {type, amount}
      dealCount
      meetingCount
      contacts {
        name
        email
        photoUrl
      }
    }
   `,
    accountWithConfig: gql`
      fragment AccountResultWithConfig on AccountResultWithConfig {
        accountResults {
          providerAccountId
          accountName,
          createdAt,
          updatedAt,
          reps,
          amount {type, amount}
          dealAmount {type, amount}
          dealCount,
          meetingCount,
          contacts {
            name, email, photoUrl
          }
        }
        totalCount
        startValue
      }
 `,
    accountAll: gql`
    fragment AccountResultAll on AccountResultAll {
      providerAccountId
      accountName
      createdAt
      updatedAt
      reps
      amount
      dealCount
      meetingCount
      contacts {
        name
        email
        photoUrl
      }
    }
   `,
}

const playlistFragment = {
    playlist: gql`
    fragment PlaylistType on PlaylistType {
        id
        name
        description
        customerId
        createdAt
        updatedAt
        userId 
        user { firstName lastName id }
        users { firstName lastName id }
        teams { name id }
        sharedWith { teamIds userIds all }  
        meetings { 
            id 
            title 
            date
            duration
            attendees { name role }
            relation {opportunityId stageName contactId leadId accountId} 
            sources { url thumb }  
            account { name }
        }
        snippets { 
            id meetingId name startTime endTime createdAt userId
            sharedWith { teamIds userIds all }
            meeting{ id account { name } 
                attendees { name role } 
                sources { url thumb }
            }
        }
    }
  `,
}

const bookmarkFragment = {
    bookmark: gql`
  fragment BookmarkType on BookmarkType {
      id
      customerId
      createdAt
      updatedAt
      userId 
      user { firstName lastName id }
      meetings { 
          id 
          title 
          date
          duration
          attendees { name role }
           relation {opportunityId stageName contactId leadId accountId}
          sources { url thumb }  
          account { name }
      }
      snippets { 
          id meetingId name startTime endTime createdAt userId
          sharedWith { teamIds userIds all }
          meeting{ id account { name } 
              attendees { name role } 
              sources { url thumb }
          }
      }
  }
`,
}

const conversationFragments = {
    conversation: gql`
    fragment Conversation on Conversation {
        id
        providerConversationId
        customerId
        body
        bodyText
        owner { id email firstName lastName }
        incoming
        toEmails
        private
        source
        ticketId
        supportEmail
        userId
        lastEditedAt
        lastEditedUserId 
        createdAt
        updatedAt
        attachments { name url }
    }
  `,
};

const ticketFragments = {
    ticket: gql`
    fragment Ticket on Ticket {
        companyId
        customerId
        providerTicketId
        description
        dueBy
        email
        frDueBy
        frEscalated
        id
        isEscalated
        name
        phone
        priority
        productId
        requesterId
        responderId
        source
        status
        subject
        type
        createdAt
        updatedAt
        owner { id email firstName lastName }
    }
  `,
};
export const GET_ALL_DEALS_WITH_CONFIG = gql`
 query getAllDealsWithConfig($customerId: String, $crmFilterInput:CRMFilterInput, $args:ResultSetConfig) {
     getAllDealsWithConfig(customerId: $customerId, crmFilterInput: $crmFilterInput, args:$args) {
         ...OpportunityResultWithConfig
     }
 }
 ${dealFragments.dealWithConfig}`;

export const GET_DEALS_WITH_CONFIG = gql`
    query getDealsWithConfig($customerId: String, $userId: String, $teamId: String, $crmFilterInput:CRMFilterInput, $args:ResultSetConfig) {
        getDealsWithConfig(customerId: $customerId,  userId: $userId, teamId: $teamId, crmFilterInput: $crmFilterInput, args:$args) {
            ...OpportunityResultWithConfig
        }
    }
${dealFragments.dealWithConfig}`;

export const GET_MY_DEALS_WITH_CONFIG = gql`
 query getMyDealsWithConfig($userId: String, $crmFilterInput:CRMFilterInput, $args:ResultSetConfig) {
     getMyDealsWithConfig(userId: $userId, crmFilterInput: $crmFilterInput, args:$args) {
         ...OpportunityResultWithConfig
     }
 }
 ${dealFragments.dealWithConfig}`;

export const GET_MY_TEAM_DEALS_WITH_CONFIG = gql`
 query getMyTeamDealsWithConfig($teamId: String, $crmFilterInput:CRMFilterInput, $args:ResultSetConfig) {
     getMyTeamDealsWithConfig(teamId: $teamId, crmFilterInput: $crmFilterInput, args:$args) {
         ...OpportunityResultWithConfig
     }
 }
 ${dealFragments.dealWithConfig}`;

export const GET_ALL_ACCOUNTS_WITH_CONFIG = gql`
 query getAllAccountsWithConfig($customerId: String!, $crmFilterInput:CRMFilterInput, $args:ResultSetConfig) {
     getAllAccountsWithConfig(customerId: $customerId,  crmFilter: $crmFilterInput, args:$args) {
         ...AccountResultWithConfig
     }
 }
 ${accountFragment.accountWithConfig}`;

export const GET_MY_ACCOUNTS_WITH_CONFIG = gql`
 query getMyAccountsWithConfig($userId: String!,  $crmFilterInput:CRMFilterInput, $args:ResultSetConfig) {
     getMyAccountsWithConfig(userId: $userId,  crmFilter: $crmFilterInput, args:$args) {
         ...AccountResultWithConfig
     }
 }
 ${accountFragment.accountWithConfig}`;

export const GET_MY_TEAM_ACCOUNTS_WITH_CONFIG = gql`
 query getMyTeamAccountsWithConfig($teamId: String!, $crmFilterInput:CRMFilterInput, $args:ResultSetConfig) {
     getMyTeamAccountsWithConfig(teamId: $teamId, crmFilter: $crmFilterInput, args:$args) {
         ...AccountResultWithConfig
     }
 }
 ${accountFragment.accountWithConfig}`;


export const GET_ALL_ACCOUNT = gql`
  query getAllAccounts($customerId: String!) {
     getAllAccounts(customerId: $customerId) {
      ...AccountResultAll
     }
 }
 ${accountFragment.account}
`;

export const GET_ALL_ACCOUNT_NAMES = gql`
  query getAllAccountNames($customerId: String!) {
     getAllAccounts(customerId: $customerId) {
      accountName
     }
 }
`;

export const GET_MY_TEAM_ACCOUNT = gql`
  query getMyTeamAccounts($teamId: String!) {
     getMyTeamAccounts(teamId: $teamId) {
      ...AccountResultAll
     }
 }
 ${accountFragment.account}
`;

export const GET_MY_ACCOUNT = gql`
  query getMyAccounts($userId: String!) {
     getMyAccounts(userId: $userId) {
      ...AccountResultAll
     }
 }
 ${accountFragment.account}
`;

export const GET_ACCOUNT = gql`
  query getAccount($accountId: String!) {
     getAccount(accountId: $accountId) {
      ...AccountResultAll
     }
 }
 ${accountFragment.account}
`;

export const GET_MY_TEAM_DEAL = gql`
  query getMyTeamDeals($teamId: String!) {
     getMyTeamDeals(teamId: $teamId) {
      ...OpportunityResult
     }
 }
 ${dealFragments.deal}
`;


export const GET_MY_DEALS = gql`
 query getMyDeals($userId: String!) {
     getMyDeals(userId: $userId) {
         ...OpportunityResult
     }
 }
 ${dealFragments.deal}`;

export const GET_ALL_DEALS = gql`
 query getAllDeals($customerId: String) {
    getAllDeals(customerId: $customerId) {
     ...OpportunityResult
    }
}
${dealFragments.deal}`;


export const GET_DEAL = gql`
 query getDeal($dealId: String!) {
    getDeal(dealId: $dealId) {
        ...OpportunityResult
    }
}
 ${dealFragments.deal}
`;

export const GET_MY_DEAL_MEETING = gql`
 query getMeetingsForDeal($dealId: String!) {
     getMeetingsForDeal(dealId: $dealId) {
         ...Meeting
     }
 }
 ${fragments.meeting}`;

export const GET_MEETINGS_AND_EMAILS_FOR_DEAL = gql`
 query getMeetingsAndEmailsForDeal($dealId: String!) {
  getMeetingsAndEmailsForDeal(dealId: $dealId) {
        ...MeetingsEmailResult
    }}
 ${meetingEmailFragments.MeetingsEmailResult}`;


export const GET_MEETINGS_AND_EMAILS_FOR_DEAL_COUNT = gql`
 query getMeetingsAndEmailsForDealCount($dealId: String!) {
  getMeetingsAndEmailsForDealCount(dealId: $dealId) {
        Meeting {
            year
            byWeek { week count }
        }
        Email {
            year
            byWeek {  week count }
        }
     }
}`;

export const GET_FORECAST = gql`
    query dealForecast($opportunityForecastInput: OpportunityForecastInput) {
        dealForecast(opportunityForecastInput: $opportunityForecastInput) {
            firstName
            lastName
            jobTitle
            managerId
            reps {
                firstName
                lastName
                jobTitle
                userId
                targetAttainment {
                    totalActualSales
                    totalTargetSales
                    percentage
                }
                pipelineCoverage  {
                    totalForecastOpenDeals
                    countForecastOpenDeals
                    coverage
                }
                commit {
                    sum
                    count
                }
                mostLikely {
                    sum
                    count
                }
                bestCase {
                    sum
                    count
                }
            }
        }
    }
`;


export const GET_MEETINGS_AND_EMAILS_COUNT_FOR_DEAL_COUNT_BY_NAME = gql`
 query getMeetingsAndEmailsCountForDealByName($dealId: String!) {
  getMeetingsAndEmailsCountForDealByName(dealId: $dealId) {
    Meeting{
      contacts{
        email
        name
        byYear {
          year
          byWeek  {
            week
            count
            meetingIds { meetingId }
          }
        }
      }
      reps {
        email
        name
        byYear {
          year
          byWeek  {
            week
            count
            meetingIds { meetingId }
          }
        }
      }
    }
    Email {
      contacts{
        email
        name
        byYear {
          year
          byWeek  {
            week
            count
            emailIds { id emailThreadId}
          }
        }
      }
      reps {
        email
        name
        byYear {
          year
          byWeek  {
            week
            count
            emailIds { id emailThreadId}
          }
        }
      }
    }
  }
}`;


export const ADD_COMMENT = gql`
  mutation CreateComment($comment: CommentInput!) {
    createComment(comment: $comment) {
      id
      transcriptId
      author
      authorUserId
      meetingId
      time
      text
      rating
      isPrivate
      commentAtTimeStamp
   }
  }
`;

export const EDIT_COMMENT = gql`
  mutation EditComment($comment: CommentInput!) {
    editComment(comment: $comment) {
      id
      transcriptId
      author
      authorUserId
      meetingId
      time
      text
      rating
      isPrivate
      commentAtTimeStamp
   }
  }
`;

export const COMMENTS_SENT = gql`
    query meetingsWithCommentsSent($userId: String!) {
        meetingsWithComments (userId: $userId) {
            comments { id transcriptId text author authorUserId isPrivate commentAtTimeStamp time}
            meeting { id title date }
        }
    }
`;

export const COMMENTS_RECEIVED = gql`
    query meetingsWithCommentsReceived($email: String!) {
        meetingsWithComments (email: $email) {
            comments { id transcriptId text author authorUserId isPrivate commentAtTimeStamp time}
            meeting { id title date }
        }
    }
`;

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: String!) {
    deleteComment(id: $id) {
        ok  
   }
  }
`;

export const GET_COMMENTS = gql`
  query Comments($transcriptId: String!) {
    comments(transcriptId: $transcriptId) {
      id
      transcriptId
      author
      authorUserId
      meetingId
      time
      text
      rating
      isPrivate
      commentAtTimeStamp
    }
  }
`;

export const GET_COMMENTS_FOR_MEETING= gql`
  query commentsByMeetingId($meetingId: String!) {
    commentsByMeetingId(meetingId: $meetingId) {
      id, comments {
      id
      transcriptId
      author
      authorUserId
      meetingId
      time
      text
      rating
      isPrivate
      commentAtTimeStamp
      }
    }
  }
`;

export const GET_COMMENTS_COUNT = gql`
  query CommentsCount($meetingId: String!) {
    commentCounts(meetingId: $meetingId) {
      transcriptId
      count
    }
  }
`;

export const GET_TOTAL_SUBSCRIPTION_COUNT = gql`
  query subscriptions($customerId: String) {
    subscriptions(customerId: $customerId) {
      planQuantity
      licenseType
    }
  }
`;

export const GET_COUNTS_BY_LICENSE_TYPE = gql`
  query countsByLicenseType($customerId: String!) {
    countsByLicenseType(customerId: $customerId) {
      id
      count
    }
  }
`;

export const POST_COMPANY = gql`
  mutation upsertCustomer($customer: CustomerInput!) {
    upsertCustomer(customer: $customer) {
      id
   }
  }
`;

export const GET_USER = gql`
  query user($userName: String!) {
    user(userName: $userName) {
     ...User
    }
  }
  ${userFragments.user}
`;

export const POST_USER = gql`
    mutation createUser($user:UserInput) {createUser(user:$user) {id,email,firstName,lastName,customer{name}, phone}}
`;

export const EDIT_USER = gql`
    mutation editUser($id:String!,$user:UserInput) {editUser(id:$id,user:$user) {id}}
`;

export const DELETE_USER = gql`
mutation deleteUser($id: String!) {
  deleteUser(id:$id) {
    ok
  }
}`;
export const UPDATE_PROFILE = gql`
    mutation updateProfile($id:String!,$profile:ProfileInput!) {updateProfile(id:$id,profile:$profile) { ...User}}
    ${userFragments.user}
`;
export const UPDATE_AUTHORIZATION = gql`
    mutation updatRoleAndLicense($id:String!,$authorize:AuthorizeInput!) {updatRoleAndLicense(id:$id,authorize:$authorize) { ...User}}
    ${userFragments.user}
`;
export const UPDATE_SYNCON = gql`
    mutation updateSyncOn($id:String!,$syncOn:Boolean!) {updateSyncOn(id:$id,syncOn:$syncOn) { ...User}}
    ${userFragments.user}
`;
export const UPDATE_PASSWORD = gql`
    mutation updatePassword($userPassword:UserPassword!) {updatePassword(userPassword:$userPassword) {ok message}
    }
`;

export const VERIFY_EMAIL = gql`
    mutation verifyEmail($token:String!,$otp: String!) {verifyEmail(token:$token, otp:$otp) {ok message}
    }
`;

export const GET_COMPANIES = gql`
  query customers($host: String) {
    customers(host:$host) {
      ...CustomerInfo
    }
  }
  ${customerFragments.customer}  
`;

export const GET_USERS_BY_COMPANY_FULL = gql`
  query usersByCustomerFull($customerId: String!) {
    usersByCustomerFull(customerId:$customerId) {
      id,firstName,lastName,email,login { id,  userName}  designation license,  role {name,id} teams {id, name} , status, syncOn, providers{provider accessToken service} bioMetricUrl
    }
  }
`;

export const GET_USERS_BY_COMPANY = gql`
  query usersByCustomer($customerId: String!) {
    usersByCustomer(customerId:$customerId) {
      id,firstName,lastName,email
    }
  }
`;

export const POST_EMAIL = gql`
    mutation createEmail($email:EmailInput!) {
        createEmail(email:$email) {
            id
        }
    }
`;

export const DELETE_EMAIL = gql`
mutation deleteEmail($contactEmail: String!) {
  deleteEmail(contactEmail:$contactEmail) {
    id
  }
}`;

export const GET_MEETINGS = gql`
  query completedMeetings($host: String) {
    completedMeetings(host: $host) {
           id      
            title
            date
            duration
            callType
            transcriptionLanguage
            callDisposition
            callDirection
            manager {name email id}
            listenLaterUsers {
              firstName
              lastName
              _id
            }
            sharedWith { teamIds userIds all }
            hostName
            host
            status
            users { firstName lastName }
            teams { name }
            attendees { name }
            account {name owner {id,email,firstName,lastName}}
    }
  }
`;

export const GET_MEETING_TOPICS_BY_HOST = gql`
  query completedMeetingsTopicCount($host: String) {
    completedMeetings(host: $host) {
      id
      date
      host
      callType
      transcriptionLanguage
      callDisposition
      callDirection
      listenLaterUsers {
          firstName
          lastName
          _id
      }
      analytics {topic_count {name mentions duration percentage}}
    }
  }
`;

export const GET_MEETING_METRICS_BY_HOST = gql`
  query meetingMetricsByHost($host: String!) {
    completedMeetings(host: $host) {
      id
      date
      duration
      host
      callType
      manager {name email id}  
      transcriptionLanguage
      callDisposition
      callDirection
      listenLaterUsers {
          firstName
          lastName
          _id
      }
      user { firstName lastName }
      analytics {
          longest_monologue_snippet { length }
          longest_story_snippet{ length }
          patience
          question_rate_host  
          question_rate_prospect
          number_host_questions 
          number_prospect_questions
          interactivity
          tl_ratio
          sentiment
          lead_score { name score explanation }
      }       
    }
  }
`;

export const GET_MEETING_METRICS_BY_MEETINGID = gql`
  query meetingMetricsByMeetingId($id: String!) {
    meeting(id: $id) {
      id
      date
      duration
      host
      callType
      transcriptionLanguage
      callDisposition
      callDirection
      listenLaterUsers {
          firstName
          lastName
          _id
      }
      user { firstName lastName }
      analytics {
          longest_monologue_snippet { length }
          longest_story_snippet{ length }
          patience
          question_rate_host  
          question_rate_prospect
          number_host_questions 
          number_prospect_questions
          interactivity
          tl_ratio
          sentiment
          lead_score { name score explanation }
      }       
    }
  }
`;

export const GET_MEETING_BY_PROVIDERID = gql`
  query meetingByProviderId($providerMeetingId: String!) {
    meetingByProviderId(providerMeetingId: $providerMeetingId) {
      id
      providerMeetingId
      providerUUID
      date
      host
      title
      callType
      transcriptionLanguage
      callDisposition
      callDirection
      listenLaterUsers {
          firstName
          lastName
          _id
      }
      analytics { topic_count { name mentions duration percentage } }
    }
  }
`;

export const GET_MEETING_BY_PROVIDERID_AND_DATE = gql`
  query meetingByProviderIdAndDate($providerMeetingId: String!, $date:String!) {
    meetingByProviderIdAndDate(providerMeetingId: $providerMeetingId, date: $date) {
      id
      providerMeetingId
      providerUUID
      date
      host
      title
      callType
      transcriptionLanguage
      callDisposition
      callDirection
      listenLaterUsers {
          firstName
          lastName
          _id
      }
      analytics { topic_count { name mentions duration percentage } }
    }
  }
`;


export const GET_MEETING_BY_PROVIDERUUID = gql`
  query meetingByProviderUUID($providerMeetingUUID: String!) {
    meetingByProviderUUID(providerMeetingUUID: $providerMeetingUUID) {
      id
      providerMeetingId
      providerUUID
      date
      host
      title
      callType
      transcriptionLanguage
      callDisposition
      callDirection
      listenLaterUsers {
          firstName
          lastName
          _id
      }
      analytics { topic_count { name mentions duration percentage } }
    }
  }
`;

export const GET_MEETING_TOPICS_BY_MEETINGID = gql`
  query meetingTopicByMeetingId($id: String!) {
    meeting(id: $id) {
      id
      date
      host
      title
      callType
      transcriptionLanguage
      callDisposition
      listenLaterUsers {
          firstName
          lastName
          _id
      }
      analytics { topic_count { name mentions duration percentage } }
    }
  }
`;

export const SET_RECORDING_STATUS = gql`
mutation setRecordingStatus($id:String!, $status:Boolean!) {
  setRecordingStatus(id:$id, status:$status){ id }
}`;

export const SET_MEETING_TITLE = gql`
mutation setMeetingTitle($id:String!, $title:String!) {
  setMeetingTitle(id:$id, title:$title){ id }
}`;

export const SET_MEETING_OWNER = gql`
mutation setMeetingOwner($id:String!, $ownerEmail:String!) {
  setMeetingOwner(id:$id, ownerEmail:$ownerEmail){ id }
}`;

export const UPDATE_SPEAKER_NAMES = gql`
mutation updateSpeakerNames($meetingId: String!, $names: [ChangeNameInput]!) {
  updateSpeakerNames(meetingId:$meetingId names:$names){ id }
}`;

export const SET_MEETING_TRANSCRIPTION_LANGUAGE = gql`
mutation setTranscriptionLanguage($id:String!, $transcriptionLanguage:String!) {
  setTranscriptionLanguage(id:$id, transcriptionLanguage:$transcriptionLanguage){ id }
}`;

export const SET_MEETING_STATUS = gql`
mutation setMeetingStatus($id:String!, $status:String!) {
    setMeetingStatus(id:$id, status:$status){ id }
}`;

export const ADD_TO_LISTEN_LATER = gql`
mutation addListenTo($id:String!, $userId:String!) {
  addListenTo(id:$id, userId:$userId){ id }
}`;

export const REMOVE_FROM_LISTEN_LATER = gql`
mutation removeListenTo($id:String!, $userId:String!) {
  removeListenTo(id:$id, userId:$userId){ id }
}`;

export const GET_FULL_MEETING_BY_ID = gql`
  query getFullMeetingById($id: String!) {
    meeting(id: $id) {
      ...Meeting
    }
    
  }${fragments.meeting}`;
export const GET_MEETING_BY_ID = gql`
  query getMeetingById($id: String!) {
    meeting(id: $id) {
      id
      providerMeetingId
      description
      subtitle
      title
      date
      host
      callType
      hostId
      customerId
      duration
      transcriptionLanguage
      callDisposition
      listenLaterUsers {
          firstName
          lastName
          _id
      }
      sources { thumb url playURL}
      attendees { name email }
      account { name }
    }
  }
`;

export const GET_MEETING_NOTES = gql`
  query meeting($id: String!) {
    meeting(id: $id) {
      id,
      callType
      transcriptionLanguage
      analytics{notes}
    }
  }
`;

export const UPDATE_NOTES = gql`
mutation updateNotes($meetingId: String!, $notes: String) {
  updateNotes(meetingId: $meetingId, notes: $notes) { id }
}`;


export const GET_MYMEETINGS = gql`
  query myMeetings($host: String) {
    myMeetings(host: $host) {
      id      
            title
            date
            duration
            sharedWith { teamIds userIds all }
            hostName
            callType
            transcriptionLanguage
            callDisposition
            host
            status
            users { firstName lastName }
            teams { name }
            attendees { name }
            account {name owner {id,email,firstName,lastName}}
    }
    }
`;

export const GET_UPCOMMING_MEETINGS = gql`
  query upcomingMeetings($host: String) {
    upcomingMeetings(host: $host) {
      ...Meeting
    }
   }
  ${fragments.meeting}  
`;

export const LOGIN = gql`
  query login($userName: String!,$password:String!) {
    login(userName: $userName,password: $password) {
      token,
      user{...User},
      message
    }
  }
  ${customerFragments.customer} , 
  ${userFragments.user}
`;

export const GET_TRANSCRIPTS = gql`
  query Transcript($meetingId: String!) {
    transcript(meetingId: $meetingId) {
      id
      meetingId
      transcripts {id start  end  name text subTopics  topics  trackers 
    }
  }  
  }
`;

export const APPLY_FILTER_TRANSCRIPTS = gql`
  query filterTranscript($searchText:String!,$meetingId: String!,$topics:[String],$trackers:[String],$foundOnly:Boolean) {
    filterTranscript(searchText:$searchText,meetingId: $meetingId,topics:$topics,trackers:$trackers,foundOnly:$foundOnly) {
      id
      meetingId
      transcripts {id start  end  name   text subTopics  topics  trackers  chunkCategories{category chunks}
      highlights {
          text
          topics
          subTopics
          trackers
          name
          title
          subtitle
          description
          notes
        }      
    }      
      }
      
  }  
`;


export const GET_QUESTION_ANSWERS = gql`
  query getQuestionAnswers($userId: String!){
    myQuestions(userId:$userId) {
        id
        objectId
        objectType
        questions { id question answer askedBy rating}
    }
}`;


export const GET_QA_FOR_MEETING_DEAL = gql`
 query questionByObjectId($objectId: String!, $objectType: String!) {
    questionByObjectId(objectId:$objectId, objectType:$objectType) {
        id
       questions { id question answer askedBy rating}
    }
}`;

export const SET_ANSWER_RATING = gql`
mutation setAnswerRating($id: String!, $questionId: String!, $rating: Int!) {
  setAnswerRating(id:$id, questionId:$questionId, rating:$rating) {
     id
     questions {id}
  }
}`;

export const GET_SNIPPETS = gql`
  query mySnippets($userId: String!) {
    mySnippets(userId: $userId) {
      id
      meetingId
      customerId
      name
      description
      startTime
      endTime
      createdAt
      userId 
      users {firstName lastName}
      teams {name}
      sharedWith { teamIds  userIds all} 
    }
  }
`;

export const GET_SNIPPETS_BY_MEETINGID = gql`
  query snippetsByMeetingId($meetingId: String!) {
    snippetsByMeetingId(meetingId: $meetingId) {
      id
      meetingId
      customerId
      name
      description
      startTime
      endTime
      createdAt
      userId 
      users {firstName lastName}
      teams {name}
      sharedWith { teamIds  userIds all} 
    }
  }
`;

export const GET_SNIPPET_BY_NAME = gql`
  query snippetByName($name: String!) {
    snippetByName(name: $name) {
      id
      meetingId
      customerId
      name
      description
      startTime
      endTime
      createdAt
      userId 
      users {firstName lastName}
      teams {name}
      sharedWith { teamIds userIds all} 
    }
  }
`;

export const COACHING_BY_MANAGER = gql`
  query coachingByManager($managerId:String, $managerEmail:String, $dateFrom:String, $dateTo:String) {
      coachingByManager(managerId: $managerId,  managerEmail: $managerEmail, dateFrom: $dateFrom,  dateTo: $dateTo) {
      callsAsListenedByManager {
          _id {
            hostId
            host
            hostName
            jobTitle
            lastListenedDate
          }
          meetings {
            id
            title
            duration
            date
          }
        }
        callsWithScorecardsByManager {
          _id {
            hostId
            host
            hostName
            jobTitle
            lastScoredDate
          }
          meetings {
            id
            title
            duration
            date
          }
        }
        callsAsAttendeesByManager {
          _id {
            hostId
            host
            hostName
            jobTitle
            lastAttendedDate
          }
          meetings {
            id
            title
            duration
            date
          }
        }
        callsWithCommentsByManager {
          _id {
            hostId
            host
            hostName
            jobTitle
            lastCommentedDate
          }
          meetings {
            id
            title
            duration
            date
          }
        }
      }
    }
`;

export const GET_SNIPPET_BY_ID = gql`
  query snippet($id: String!) {
    snippet(id: $id) {
      id
      meetingId
      customerId
      name
      description
      startTime
      endTime
      createdAt
      userId 
      users {firstName lastName}
      teams {name}
      sharedWith { teamIds userIds all}  
    }
  }
`;

export const CREATE_SNIPPET = gql`
mutation createSnippet($snippet: SnippetInput!) {
  createSnippet(snippet:$snippet) {
     id
     meetingId
     customerId
     name
     description
     startTime
     endTime
     userId
  }
}`;

export const EDIT_SNIPPET = gql`
mutation editSnippet($id: String!, $snippet: SnippetInput!) {
  editSnippet(id:$id, snippet:$snippet) {
    id
  }
}`;

export const UPDATE_SNIPPET_SHARED_WITH = gql`
mutation updateSharedWith($id: String!, $sharedWith: SharedWithInput!) {
  updateSharedWith(id:$id, sharedWith:$sharedWith){
    id
  }
}`;

export const SHARE_SNIPPET_WITH_TEAMS = gql`
mutation ShareWithTeams($id: String!, $sharedWithTeams: [String]!) {
  ShareWithTeams(id:$id, sharedWithTeams:$sharedWithTeams){
    id
    userId
    customerId
    name
    startTime
    endTime
    createdAt
    meetingId
    sharedWith { teamIds userIds }
    users {firstName lastName}
    teams {name}
    description
  }
}`;

export const SHARE_SNIPPET_WITH_USERS = gql`
mutation ShareWithUsers($id: String!, $sharedWithUsers: [String]!) {
  ShareWithUsers(id:$id, sharedWithUsers:$sharedWithUsers){
     id
    userId
    customerId
    name
    startTime
    endTime
    createdAt
    meetingId
    sharedWith { teamIds userIds }
    users {firstName lastName}
    teams {name}
    description
  }
}`;

export const SHARE_SNIPPET_ALL = gql`
mutation ShareWithAll($id: String!) {
  ShareWithAll(id:$id){
    id
  }
}`;

export const SHARE_MEETING_WITH_TEAM = gql`
mutation shareMeetingWithTeam($id:String!, $teamId:String!) {
  shareMeetingWithTeam(id:$id, teamId:$teamId){
    id
  }
}`;

export const SHARE_MEETING_WITH_USER = gql`
mutation shareMeetingWithUser($id:String!, $userId:String!) {
  shareMeetingWithUser(id:$id, userId:$userId){
    id
  }
}`;

export const SHARE_MEETING_WITH_TEAMS = gql`
mutation shareMeetingWithTeams($id:String!, $sharedWithTeams:[String]!) {
  shareMeetingWithTeams(id:$id, sharedWithTeams:$sharedWithTeams){
    id
  }
}`;

export const SHARE_MEETING_WITH_USERS = gql`
mutation shareMeetingWithUsers($id:String!, $sharedWithUsers:[String]!) {
  shareMeetingWithUsers(id:$id, sharedWithUsers:$sharedWithUsers){
    id
  }
}`;

export const SHARE_MEETING_ALL = gql`
mutation shareMeetingWithAll($id:String!, $all:Boolean!) {
  shareMeetingWithAll(id:$id, all:$all){
    id
  }
}`;

export const DELETE_SNIPPET = gql`
mutation deleteSnippet($id: String!) {
  deleteSnippet(id:$id) {
    id
  }
}`;

// Playlists API
export const GET_PLAYLISTS = gql`
  query playlists($customerId: String!) {
    playlists(customerId: $customerId) {
        ...PlaylistType
        }
    }
 ${playlistFragment.playlist}`;

export const GET_PLAYLIST_BY_ID = gql`
  query playlist($id: String!) {
    playlist(id: $id) {
      ...PlaylistType
        }
    }
 ${playlistFragment.playlist}`;

export const GET_MY_PLAYLISTS = gql`
  query myPlaylists($userId: String!) {
    myPlaylists(userId: $userId) {
      ...PlaylistType
        }
    }
 ${playlistFragment.playlist}`;

export const GET_PLAYLIST_BY_NAME = gql`
  query playlistByName($name: String!) {
    playlistByName(name: $name) {
       ...PlaylistType
        }
    }
 ${playlistFragment.playlist}`;

export const GET_PLAYLIST_SHARED_WITH_USER = gql`
  query playlistSharedWithUser($userId: String!) {
    playlistSharedWithUser(userId: $userId) {
      ...PlaylistType
        }
    }
 ${playlistFragment.playlist}`;

export const GET_PLAYLIST_FOR_SNIPPET = gql`
  query getPlaylistsOfSnippet($snippetId: String!) {
    getPlaylistsOfSnippet(snippetId: $snippetId) {
     name
    }
  }
`;


export const CREATE_PLAYLIST = gql`
mutation createPlaylist($playlist: PlaylistTypeInput!) {
  createPlaylist(playlist:$playlist) {
    id
  }
}`;

export const EDIT_PLAYLIST = gql`
mutation updatePlaylist($id: String!, $name:String, $description:String, $userId:String) {
  updatePlaylist(id:$id, name: $name, description:$description, userId:$userId) {
    id
  }
}`;

export const UPDATE_PLAYLIST_SHARED_WITH = gql`
mutation updatePlaylistSharedWith($id: String!, $sharedWith: SharedWithInput!) {
  updatePlaylistSharedWith(id:$id, sharedWith:$sharedWith){
    id
  }
}`;

export const SHARE_PLAYLIST_WITH_TEAMS = gql`
mutation playlistShareWithTeams($id: String!, $sharedWithTeams: [String]!) {
  playlistShareWithTeams(id:$id, sharedWithTeams:$sharedWithTeams){
    id
  }
}`;

export const SHARE_PLAYLIST_WITH_USERS = gql`
mutation playlistShareWithUsers($id: String!, $sharedWithUsers: [String]!) {
  playlistShareWithUsers(id:$id, sharedWithUsers:$sharedWithUsers){
    id
  }
}`;

export const SHARE_PLAYLIST_ALL = gql`
mutation playlistShareWithAll($id: String!) {
  playlistShareWithAll(id:$id){
    id
  }
}`;

export const DELETE_PLAYLIST = gql`
mutation deletePlaylist($id: String!) {
  deletePlaylist(id:$id) {
    id
  }
}`;

export const ADD_SNIPPET_TO_PLAYLIST = gql`
mutation addSnippetToPlaylist($id: String!, $snippetId: String!) {
  addSnippetToPlaylist(id:$id, snippetId:$snippetId) {
    id
  }
}`;

export const ADD_MEETING_TO_PLAYLIST = gql`
mutation addMeetingToPlaylist($id: String!, $meetingId: String!) {
  addMeetingToPlaylist(id:$id, meetingId:$meetingId) {
    id
  }
}`;

export const REMOVE_SNIPPET_FROM_PLAYLIST = gql`
mutation removeSnippetFromPlaylist($id: String!, $snippetId: String!) {
  removeSnippetFromPlaylist(id:$id, snippetId:$snippetId) {
    id
  }
}`;

export const REMOVE_MEETING_FROM_PLAYLIST = gql`
mutation removeMeetingFromPlaylist($id: String!, $meetingId: String!) {
  removeMeetingFromPlaylist(id:$id, meetingId:$meetingId) {
    id
  }
}`;

export const UPDATE_MEETING = gql`
  mutation createMeeting($meeting:MeetingInput) {
    createMeeting(meeting:$meeting) {
      id,providerMeetingId,title
    }
}`;

export const EDIT_MEETING = gql`
  mutation editMeeting($id: String!, $meeting:MeetingInput) {
    editMeeting(id: $id, meeting:$meeting) {
      id,providerMeetingId,title
    }
}`;

export const CREATE_STATE = gql`
  mutation createState($state:StateInput!) {
    createState(state:$state) {
      id, name
    }
}`;

export const EDIT_STATE = gql`
  mutation editState($id: String!, $state:StateInput!) {
    editState(id:$id, state:$state) {
        id, name, userId, type, data,dealWarningEnabled
    }
}`;

export const CREATE_ALERT = gql`
  mutation createAlert($alert:AlertInput!) {
    createAlert(alert:$alert) {
      id, name
    }
}`;

export const EDIT_ALERT = gql`
  mutation editAlert($id: String!, $alert:AlertInput!) {
    editAlert(id:$id, alert:$alert) {
        id, name, userId, objectType, objectId, triggerOn, state
    }
}`;

export const DELETE_STATE = gql`
  mutation deleteState($id:String!) {
    deleteState(id:$id) {
      id
    }
}`;

export const DELETE_ALERT = gql`
  mutation deleteAlert($id:String!) {
    deleteAlert(id:$id) {
      id
    }
}`;

export const ADD_TRACKER = gql`
  mutation addTracker($customerId:String!,$tracker:TrackerInput) {
    addTracker(customerId:$customerId,tracker:$tracker) {
      id, trackers {name, keywords, topics, whenSaidBy, callScope, trackIn, teams, relatedForms, lastUpdatedBy{firstName, lastName},lastUpdatedDate}
    }
}`;

export const UPDATE_SYSTEM_TOPIC = gql`
  mutation updateSystemTopic($customerId:String!,$topic:TopicsInput) {
    updateSystemTopic(customerId:$customerId, topic:$topic) {
      id, topics { name, topics, whenSaidBy, callScope, trackIn, teams, enabled }
    }
}`;

export const UPDATE_SYSTEM_TOPICs = gql`
  mutation updateSystemTopics($customerId:String!,$topics:[TopicsInput]) {
    updateSystemTopics(customerId:$customerId, topics:$topics) {
      id, topics { name, topics, whenSaidBy, callScope, trackIn, teams, enabled }
    }
}`;

export const UPDATE_TRANSCRIPTS = gql`
  mutation UpdateTranscript($meetingId:String!, $transcript:TranscriptInfoInput!,$newText:String!,$updatedBy:String!) {
    updateTranscript(meetingId:$meetingId, transcript:$transcript,newText:$newText,updatedBy:$updatedBy) {
      meetingId
   }
  }
`;

export const SEARCH_MEETINGS = gql`
  query Search($searchTerm: String!) {
    search(searchTerm: $searchTerm) {
      description
      subtitle
      thumb
      title
      date
      host
      id
      callType
      transcriptionLanguage
      callDisposition
      duration
    }
  }  
`;

export const SEARCH_TRANSCRIPTS = gql`
  query SearchTranscripts($searchTerm: String!) {
    searchTranscripts(searchTerm: $searchTerm) {
      meeting { description
      subtitle
      thumb
      title
      date
      host
      id
      duration}
      score
      transcripts {id start  end  name  text subTopics  topics  trackers  chunkCategories{category chunks}
      }
    }
  }  
`;

export const GET_REPS = gql`
  query hostList($searchTerm: String!) {
    hostList(searchTerm:$searchTerm)
  }
`;
export const GET_OPPORTUNITY_TYPES = gql`
  query getOpportunityTypes {
    getOpportunityTypes
  }
`;

export const GET_SPEAKERS = gql`
  query speakerList($searchTerm: String!) {
    speakerList(searchTerm:$searchTerm)
  }
`;

export const GET_STAGES = gql`
  query stageList($searchTerm: String!) {
    stageList(searchTerm:$searchTerm)
  }
`;

export const APPLY_FILTER = gql`
    query searchDeals($searchTerm: FilterInput!) {
        searchDeals(searchTerm: $searchTerm) {
            searchResult {
                meeting{...Meeting}
                opportunityId
                opportunityName
                contactName
                amount
                closeDate 
                stageName
                accountId
                accountName
                leadId
                leadName
            }
        }
    }
    ${fragments.meeting}  
`;


export const APPLY_FILTER_MEETING_ID = gql`
  query searchDealsMeetingId($searchTerm: FilterInput!) {
    searchDeals(searchTerm: $searchTerm) {
        searchResult {
            accountName
            meeting {
                id      
                title
                date
                duration
                callType
                transcriptionLanguage
                callDisposition
                sharedWith { teamIds userIds all }
                hostName
                host
                status
                users { id firstName lastName }
                teams { id name }
                attendees { name }
                account {id name owner {id,email,firstName,lastName}}
                sources { url }
                highLights { name text topics subTopics trackers title }
                transcriptHighlights { name text topics subTopics trackers title }
                transcripts { id name text topics trackers }
                analytics { sentiment tl_ratio interactivity patience question_rate_host
                            longest_story_snippet { length start end }
                            longest_monologue_snippet { length start end } 
                            lead_score { name score explanation }
                            }
            }
        }
    }
  }
`;

export const GET_ROLES = gql`
  query getRoles($customerId: String!) {
    roles(customerId: $customerId) {
      ...Role
  }
  }
  ${fragments.role}
`;

export const ALL_ROLES = gql`
  query allRoles {
    allRoles {
      ...Role
  }
  }
  ${fragments.role}
`;
export const GET_ROLES_BY_CUSTOMER = gql`
  query getRolesByCustomer($customerId: String!) {
    roles(customerId: $customerId) {
      id
      name
      updatedAt
      users{ firstName lastName teams { name }}
  }
  }
`;

export const GET_ROLE_BY_ID = gql`
  query getRoleById($id: String!) {
    role(id: $id) {
      ...Role
  }
  }
  ${fragments.role}  
`;

export const GET_ROLE_BY_NAME = gql`
  query roleByName($name: String!) {
    roleByName(name: $name) {
      ...Role
  }
  }
  ${fragments.role}  
`;

export const GET_USERS_FOR_ROLE_BY_NAME= gql`
  query getUsersForRoleByName($name: String!) {
    roleByName(name: $name) {
        name
        users  { firstName lastName email id }
        ...Role
    }
  } 
  ${fragments.role}  
`;

export const MEETING_LISTENED_TO = gql`
    mutation postListenedByUser($id:String!, $userId:String!) {
        postListenedByUser(id:$id, userId:$userId) {id}
    }
`;

export const MEETING_READ_BY = gql`
    mutation postViewedByUser($id:String!, $userId:String!) {
        postViewedByUser(id:$id, userId:$userId) {id}
    }
`;


export const POST_ROLE = gql`
    mutation createRole($role:RoleInput!) {createRole(role:$role) {id}}
`;

export const UPDATE_ROLE = gql`
    mutation updateRole($id:String!,$role:RoleInput!) {updateRole(id:$id,role:$role) {id}}
`;
export const GET_TEAMS = gql`
  query teams($customerId: String) {
    teams(customerId: $customerId) {
      ...Team
    }
  }
  ${fragments.team}  
`;

export const GET_TEAMS_FULL = gql`
  query teamsFull($customerId: String) {
    teamsFull(customerId: $customerId) {
      ...Team
    }
  }
  ${fragments.team}  
`;

export const GET_USERS_FOR_TEAM = gql`
  query team($id: String!) {
    team(id: $id) {
      customerId
      name
      userDetails {firstName lastName email id}
      manager {firstName lastName email id}
    }
  }
`;

export const POST_TEAM = gql`
    mutation createTeam($team:TeamInput!) {createTeam(team:$team) {id}}
`;
export const DELETE_TEAM = gql`
    mutation deleteTeam($id:String!) {deleteTeam(id:$id) {ok}}
`;
export const UPDATE_TEAM = gql`
    mutation updateTeam($id:String!,$team:TeamInput!) {updateTeam(id:$id,team:$team) {id}}
`;
export const ADD_USER_TEAM = gql`
    mutation addUserToTeam($id:String!,$userId:String!) {addUserToTeam(id:$id,userId:$userId) {id}}
`;
export const ADD_USER_TEAMS = gql`
    mutation addUserToTeams($userId:String!,$teamIds:[String]!) {addUserToTeams(userId:$userId,teamIds:$teamIds) {status}}
`;
export const ADD_USERS_TEAM = gql`
    mutation addUsersToTeam($teamId:String!,$userIds:[String]!) {addUsersToTeam(teamId:$teamId,userIds:$userIds) {status}}
`;
export const REMOVE_USER_TEAMS = gql`
    mutation removeUserFromTeams($userId:String!,$teamIds:[String]!) {removeUserFromTeams(userId:$userId,teamIds:$teamIds) {status}}
`;
export const POST_SHAREDLINK = gql`
    mutation createSharedLink($sharedLink: SharedLinkInput!) {createSharedLink(sharedLink:$sharedLink) {id}}
`;

export const GET_SHARED_LINK = gql`
    query sharedLinkByUrlInstance($urlInstance: String!) {sharedLinkByUrlInstance(urlInstance:$urlInstance) {
      id
      urlInstance
      meetingId
      meeting {...Meeting}
      customerId
      customer {...CustomerInfo}
      name,
      title,
      startTime
      endTime
      createdAt,
		  userId
		  notifyMe
      user{...User}
    }}
    ${fragments.meeting},
    ${customerFragments.customer} , 
    ${userFragments.user}
`;

export const POST_RESTRICTEDACCESS = gql`
    mutation authorizeRestrictedAccess($restrictedAccess: RestrictedAccess!) {
      authorizeRestrictedAccess(restrictedAccess: $restrictedAccess) {
        restrictedToken
        user {
          name
          email
        }
      }
    }
`;

export const GET_RESTRICTEDACCESS = gql`
    query getRestrictedToken($restrictedAccess: RestrictedAccess!) {
      getRestrictedToken(restrictedAccess: $restrictedAccess) 
    }
`;

export const GET_TOPICS_BY_CUSTOMER = gql`
  query getTopicsByCustomer($id: String!) {
    customer(id: $id) {
        id
        topics { name }
    }
  }
`;

export const GET_SUBTOPICS_BY_CUSTOMER = gql`
  query getSubTopicsByCustomer($id: String!) {
    customer(id: $id) {
        id
        topics { name topics }
    }
  }
`;

export const GET_TRACKERS_BY_CUSTOMER = gql`
  query getTrackersByCustomer($id: String!) {
    customer(id: $id) {
        id
        trackers { name topics}
    }
  }
`;

export const GET_USERNAMES = gql`
  query getUsernames($customerId: String!) {
    usersByCustomer(customerId:$customerId) {
       firstName lastName email id
    }
  }
`;


export const GET_AVG_DURATION_FOR_TOPICS = gql`
  query getAvgForTopics($filter:MeetingStatisticFilterInput) {
    getAvgForTopics(filter: $filter) {
      name    
      durations { host hostName duration }
    }
  }
`;

export const GET_AVG_DURATION_FOR_SUBTOPICS = gql`
  query getAvgForSubTopics($filter:MeetingStatisticFilterInput) {
    getAvgForSubTopics(filter: $filter) {
      name    
      durations { host hostName duration }
    }
  }
`;

export const GET_AVG_DURATION_BY_TOPICS = gql`
  query getAvgByTopics($filter:MeetingStatisticFilterInput) {
    getAvgByTopics(filter: $filter) {
      topic    
      duration
    }
  }
`;


export const GET_AVG_DURATION_FOR_TRACKERS = gql`
  query getAvgForTrackers($filter:MeetingStatisticFilterInput) {
    getAvgForTrackers(filter: $filter) {
      name    
      durations { host hostName duration }
    }
  }
`;


export const GET_AVG_DURATION_BY_TRACKERS = gql`
  query getAvgByTrackers($filter:MeetingStatisticFilterInput) {
    getAvgByTrackers(filter: $filter) {
      topic    
      duration
    }
  }
`;

export const GET_COMPLETED_MEETINGS_WITH_TEAM_AND_SCORECARD = gql`
    query getCompletedMeetingsWithTeamAndScorecard($completedDateMin: String!, $completedDateMax: String) {
        getCompletedMeetingsWithTeamAndScorecard(completedDateMin: $completedDateMin, completedDateMax: $completedDateMax) {
            Meeting{
                id
                title
                host
                date
                duration
                attendees {name phone email}
                callDisposition
                teams {name}
                analytics {
                    tl_ratio
                    longest_monologue_snippet {length}
                    longest_story_snippet {length}
                    interactivity
                    patience
                    question_rate_host
                    question_rate_prospect
                    notes
                    lead_score {name score explanation}
                }
            }
            Scorecard {name weightedAverage scores }
            managerName
        }
    }
`;


export const GET_COMPLETED_MEETINGS_BY_DATE_RANGE = gql`
    query completedMeetingsByDateRange($completedDateMin: String!, $completedDateMax: String) {
        completedMeetingsByDateRange(completedDateMin: $completedDateMin, completedDateMax: $completedDateMax) {
            id
            title
            host
            hostName
            date
            subtitle
            date
            duration
            description
            thumb
            callType
            transcriptionLanguage
            callDisposition
            users { firstName lastName }
            teams { name }  
            attendees { email name role status }
            analytics {
                longest_monologue_snippet { length end start }
                longest_story_snippet{ length end start}
                patience interactivity tl_ratio sentiment 
                question_rate_host  question_rate_prospect
                number_host_questions number_prospect_questions
                lead_score { name score explanation }
                subtopic_count { name duration  percentage mentions }
                topic_count { name duration percentage mentions }
                tracker_count { name duration percentage mentions }
                talktrack { userid duration  percentage  }
            }
        }
    }
`;

export const GET_MEETINGS_BY_DATE_RANGE = gql`
    query meetingsByDateRange($dateMin: String!, $dateMax: String) {
        meetingsByDateRange(dateMin: $dateMin, dateMax: $dateMax) {
            id
            title
            host
            hostName
            date
            subtitle
            date
            duration
            description
            thumb
            callType
            transcriptionLanguage
            callDisposition
            users { firstName lastName }
            teams { name }  
            attendees { email name role status }
            analytics {
                longest_monologue_snippet { length end start }
                longest_story_snippet{ length end start}
                patience interactivity tl_ratio sentiment
                question_rate_host  question_rate_prospect
                number_host_questions number_prospect_questions
                lead_score { name score explanation }
                subtopic_count { name duration  percentage mentions }
                topic_count { name duration percentage mentions }
                tracker_count { name duration percentage mentions }
                talktrack { userid duration  percentage  }
            }
        }
    }
`;


export const FILTER_MEETINGS = gql`
    query filterMeeting($meetingFilterInput: MeetingFilterInput) {
        filterMeeting(filter: $meetingFilterInput) {
          ...MeetingInfo
     }
 }
 ${partialFragments.partialMeeting}`;


export const GET_AGGREGATESFOR_ME = gql`
query getAggregatesForMe($userId: String!) {
  getAggregatesForMe(userId:$userId) {
  deals
  meetings
  accounts
  }
}`;


export const ALL_AGGREGATESFOR_ME = gql`
query allAggregatesForMe($userId: String!){
    allAggregatesForMe (userId: $userId){
        meetings
        deals
        accounts
        snippets
        playlists
        comments
        alerts
        states
        lastMeeting{date}
        lastSnippet{createdAt}
        lastPlaylist{createdAt}
    }
}`

export const GET_COUNTS_AND_DURATIONS = gql`
query getCountsAndDurations($countFilter:MeetingAggregateInput) {
  getCountsAndDurations(filter:$countFilter) {
    host
        avgHostMeeting
        totalHostMeeting
        avgHostDuration
        totalHostDuration
        totalAttDuration
        avgAttDuration
        totalAttMeeting
        avgAttMeeting
  }
}
`;

export const GET_COMPLETED_MEETING_COUNT = gql`
query completedMeetingCount($completedDateMin:String,$completedDateMax:String,$customerId:String,$userId:String) {
   completedMeetingsCount(completedDateMin:$completedDateMin, completedDateMax:$completedDateMax, customerId:$customerId,userId:$userId) 
} 
`;

export const GET_MEETING_ANALYTICS = gql`
query getMeetingAnalytic($meetingAnalyticsInput:MeetingAnalyticsInput) {
    getMeetingAnalytic(filter:$meetingAnalyticsInput) {
        hostName
        hostId
        optimal
        meetings {
            date
            id
            value
            title
        }
    }
} 
`;

export const GET_ALL_COMPLETED_MEETINGS = gql`
  query getAllCompletedMeetings($customerId: String) {
    getAllCompletedMeetings(customerId: $customerId) {
      ...Meeting
    }
  }
  ${fragments.meeting}  
`;

export const GET_ALL_IN_PROGRESS_MEETINGS = gql`
  query getAllInProgressMeetings($customerId: String) {
    getAllInProgressMeetings(customerId: $customerId) {
      ...Meeting
    }
  }
  ${fragments.meeting}  
`;


export const GET_TOPICS_FOR_MEETINGID = gql`
  query getTopicsForMeetingId($meetingId: String!) {
    transcript(meetingId: $meetingId) {
        id
        transcripts {id start  end  name text subTopics  topics  trackers }
    }
  }
`;

export const GET_ACCOUNTS_AGGREGATE = gql`
  query getAccountsAggregate($userId: String!) {
    getAccountsAggregate(userId: $userId) {
        byMe
        byMyTeam
        all
    }
  }
`;

export const GET_OPPORTUNITIES_AGGREGATE = gql`
  query getOpportunitiesAggregate($userId: String!) {
    getOpportunitiesAggregate(userId: $userId) {
        byMe
        byMyTeam
        all
    }
  }
`;

export const GET_NOTIFICATION_SETTINGS = gql`
  query getNotificationSettings($userId: String) {
    states(userId: $userId) {
      id
      name
      userId
      type
      data
    }
  }
`;
export const GET_NOTIFICATION_SETTING = gql`
  query getNotificationSetting($userId: String,$name:String!) {
    stateByName(userId: $userId,name:$name) {
      id
      name
      userId
      type
      data
    }
  }
`;
export const GET_USERS_BY_ROLE = gql`
    query getUsersByRole($roleId: String!)  {
        usersByRole(roleId:$roleId) {
            id
            firstName
            lastName   
            teams{name}
        }
    }
`;


export const GET_SCORECARD_QUESTIONS_BY_CUSTOMER = gql`
  query getScorecardByCustomer($id: String!) {
    customer(id: $id) {
      id
      scorecards{
        id,
        question
      }
      scorecardList {
        id,
        name,
        forAutoScoring
        teams
        minCallDuration
        notificationSchedule
        notifiedUsers
        meetingTitleContains
        callDirection
        published
        publishedDate
        scorecards{
          id,
          question,
          weight
          scorerNotes
        }
      }
    }
  }
`;

export const GET_SCORECARD_LIST = gql`
  query getScorecardList($customerId: String!) {
    getScorecardList(customerId: $customerId) {
      id
      name
      published
    }
  }
`;

export const GET_SCORECARD_FOR_MEETING = gql`
  query getScorecard($meetingId: String!) {
    getScorecard(meetingId: $meetingId) {
      id
      customerScoreCardId
      name
      scoredBy
      updatedAt
      user {firstName lastName}
      scorecards {
        code, score, note
      }
      sharedWith { all userIds } 
      users {id,firstName lastName}
      weightedAverage
    }
  }
`;

export const PUT_SCORECARD_FOR_MEETING = gql`
  mutation createScorecard($scorecard: ScorecardInput!) {
    createScorecard(scorecard: $scorecard) {
      id
   }
  }
`;

export const GET_MEETING_BY_SCORECARD = gql`
  query getScorecardMeetingByCustomerScoreCardId($customerScoreCardId: String!) {
    getScorecardMeetingByCustomerScoreCardId(customerScoreCardId: $customerScoreCardId){
      id
      customerScoreCardId
      name
      scoredBy
      updatedAt
      user {firstName lastName}
      scorecards {
        code, score, note, order
      }
      weightedAverage
      sharedWith { all userIds } 
      users {id,firstName lastName}
      meetings {
        id
        host
        date
        title
        user { firstName lastName }
        owner
        duration
      }
    }
  }
`;

export const GET_MEETING_COUNT_FOR_SCORECARD_TEMPLATE = gql`
    query getScorecardMeetingCountByCustomerScoreCardId($customerScoreCardId:String!, $dateMin: String, $dateMax:String) {
        getScorecardMeetingCountByCustomerScoreCardId(customerScoreCardId: $customerScoreCardId, dateMin:$dateMin, dateMax:$dateMax) 
}
`;


export const CREATE_SCORECARD_TEMPLATE_FOR_CUSTOMER = gql`
    mutation insertToScorecardList($customerId:String!,$name:String!, $forAutoScoring: Boolean, $teams: [String], $minCallDuration: Int!, $notificationSchedule: String!, $notifiedUsers: [String]!, $meetingTitleContains: [String], $callDirection: [String], $scorecard: [CustomerScoreCardInput]) {
      insertToScorecardList(customerId: $customerId,name:$name, forAutoScoring: $forAutoScoring, teams: $teams, minCallDuration: $minCallDuration, notificationSchedule: $notificationSchedule, notifiedUsers: $notifiedUsers,  meetingTitleContains: $meetingTitleContains, callDirection: $callDirection,  scorecard:$scorecard){
          id
          scorecardList {
            id,
            name,
            forAutoScoring
            teams
            minCallDuration
            notificationSchedule
            notifiedUsers
            published
            publishedDate
            meetingTitleContains
            callDirection
            scorecards{
              id,
              question
              weight
              scorerNotes
            }
          }
        }
    }
`;

export const PUBLISH_SCORECARDS_FOR_TEMPLATE_FOR_CUSTOMER = gql`
    mutation publish($customerId:String!,$scorecardListId:String!) {
      publish(customerId: $customerId,scorecardListId:$scorecardListId){
          id
          scorecardList {
            id,
            name,
            forAutoScoring
            teams
            minCallDuration
            notificationSchedule
            notifiedUsers
            meetingTitleContains
            callDirection
            published
            publishedDate            
            scorecards{
              id,
              question
              weight
              scorerNotes
            }
          }
        }
    }
`;

export const UNPUBLISH_SCORECARDS_FOR_TEMPLATE_FOR_CUSTOMER = gql`
    mutation unpublish($customerId:String!,$scorecardListId:String!) {
      unpublish(customerId: $customerId,scorecardListId:$scorecardListId){
          id
          scorecardList {
            id,
            name,
            forAutoScoring
            teams
            minCallDuration
            notificationSchedule
            notifiedUsers
            meetingTitleContains
            callDirection
            published
            publishedDate            
            scorecards{
              id,
              question
              weight
              scorerNotes
            }
          }
        }
    }
`;

export const ADD_SCORECARDS_FOR_TEMPLATE_FOR_CUSTOMER = gql`
    mutation addScorecardListToCustomer($customerId:String!,$scorecardListId:String!,$scorecard: CustomerScoreCardInput!) {
      addScorecardListToCustomer(customerId: $customerId,scorecardListId:$scorecardListId,scorecard:$scorecard){
          id
          scorecardList {
            id,
            name,
            forAutoScoring
            teams
            minCallDuration
            notificationSchedule
            notifiedUsers
            meetingTitleContains
            callDirection
            published
            publishedDate            
            scorecards{
              id,
              question
              weight
              scorerNotes
            }
          }
        }
    }
`;

 export const UPDATE_SCORECARDS_FOR_TEMPLATE_FOR_CUSTOMER = gql`
    mutation updateScorecardName($customerId: String!, $scorecardListId:String!, $name: String!, $autoScoring: Boolean!, $teams: [String]!, $minCallDuration: Int!, $notificationSchedule: String!, $notifiedUsers: [String]!, $meetingTitleContains: [String], $callDirection: [String]) {
      updateScorecardName(customerId: $customerId, scorecardListId:$scorecardListId, name:$name, autoScoring:$autoScoring, teams:$teams,  minCallDuration:$minCallDuration, notificationSchedule:$notificationSchedule, notifiedUsers:$notifiedUsers, meetingTitleContains: $meetingTitleContains, callDirection: $callDirection) {
          id
        }
    }
`;
export const EDIT_SCORECARDS_FOR_TEMPLATE_FOR_CUSTOMER = gql`
    mutation editScorecardListToCustomer($customerId:String!,$scorecardListId:String!,$scorecard: CustomerScoreCardInput!) {
      editScorecardListToCustomer(customerId: $customerId,scorecardListId:$scorecardListId,scorecard:$scorecard){
          id
          scorecardList {
            id,
              name,
              forAutoScoring
              teams
              minCallDuration
              notificationSchedule
              notifiedUsers
              meetingTitleContains
              callDirection
              published
              publishedDate
              scorecards{
              id,
              question
              weight
            }
          }
        }
    }
`;
export const DELETE_SCORECARDS_FOR_TEMPLATE_FOR_CUSTOMER = gql`
    mutation deleteScorecardListFromCustomer($customerId:String!,$scorecardListId:String!) {
      deleteScorecardListFromCustomer(customerId: $customerId,scorecardListId:$scorecardListId){
          id
          scorecardList {
            id,
            name,
            published
            publishedDate
            scorecards{
              id,
              question
              weight
              scorerNotes
            }
          }
        }
    }
`;

export const DELETE_ALL_SCORECARDS_FOR_TEMPLATE = gql`
    mutation deleteAllScorecard($customerScoreCardId:String!) {
      deleteAllScorecard(customerScoreCardId: $customerScoreCardId) {
          ok
        }
    }
`;


export const SET_DEAL_INSIGHTS_BY_CUSTOMER = gql`
    mutation setDealInsights($id:String!, $dealInsights:DealInsightsInput) { 
        setDealInsights(id: $id, dealInsights: $dealInsights){
            status
        }
    }
`;

export const SET_DEFAULT_DEAL_INSIGHTS_BY_CUSTOMER = gql`
    mutation setDefaultDealInsights($customerId:String!) {
        setDefaultDealInsights(customerId: $customerId){
            status
        }
    }
`;

export const GET_DEFAULT_DEAL_INSIGHTS = gql`
    query getDefaultDealInsights {
      getDefaultDealInsights {
        noActivity
        ghosted
        topicMentions {
          pricing
          bant
          nextSteps
          competitors
        }
        overdue
        notEnoughContacts {
          contacts
          days
        }
        stalledInStage {
          stageName
          days
        }
        stakeHolders {
          title
          days
        }
      }
    }
`;


export const SET_HIDE_ALL_MEETINGS_BY_CUSTOMER = gql`
    mutation setHideAllMeetings($id:String!, $hideAllMeetings:Boolean) { 
        setHideAllMeetings(id: $id, hideAllMeetings: $hideAllMeetings){
            status
        }
    }
`;

export const GET_HIDE_ALL_MEETINGS_BY_CUSTOMER = gql`
  query getHideAllMeetings($id: String!) {
    customer(id: $id) {
      id,
      hideAllMeetings
    }
  }
`;

export const SET_LANG_MODEL_TEXT_BY_CUSTOMER = gql`
    mutation setLanguageModelText($id:String!, $text:[String]!) { 
        setLanguageModelText(id: $id, text: $text){
            status
        }
    }
`;

export const GET_LANG_MODEL_TEXT_BY_CUSTOMER = gql`
  query getLangModelText($id: String!) {
    customer(id: $id) {
      id,
      languageModelText
    }
  }
`;

export const SET_COMPETITORS_BY_CUSTOMER = gql`
    mutation setCompetitors($id: String!, $text: String!) { 
        setCompetitors(id: $id, text: $text){
            status
        }
    }
`;

export const GET_COMPETITORS_BY_CUSTOMER = gql`
  query getCompetitors($id: String!) {
    getCompetitors(id: $id)
  }
`;

export const GET_PROMPTS = gql`
  query getPrompts($id: String!) {
    getPrompts(id: $id) {
      id, name, prompt, promptType
    }
  }
`;

export const ADD_PROMPT = gql`
    mutation addPrompt($customerId:String!, $prompt:PromptInput) { 
        addPrompt(customerId: $customerId, prompt: $prompt){
            status
        }
    }
`;

export const UPDATE_PROMPT = gql`
    mutation updatePrompt($customerId:String!, $prompt:PromptInput) {
        updatePrompt(customerId: $customerId, prompt: $prompt){
            status
        }
    }
`;

export const DELETE_PROMPT = gql`
    mutation deletePrompt($customerId:String!, $name:String) { 
        deletePrompt(customerId: $customerId, name: $name){
            ok
        }
    }
`;

export const ADD_QUESTION_TO_SCORECARD = gql`
    mutation addScorecardToCustomer($id: String!, $scorecard: CustomerScoreCardInput! ) {
        addScorecardToCustomer(id: $id, scorecard: $scorecard){
            id
        }
    }
`;

export const REMOVE_QUESTION_FROM_SCORECARD = gql`
    mutation removeScorecardListFromCustomer($customerId: String!,$scorecardListId:String!, $scorecardCode: String! ) {
      removeScorecardListFromCustomer(customerId: $customerId,scorecardListId:$scorecardListId, scorecardCode: $scorecardCode){
            id
        }
    }
`;

export const RESTORE_DEFAULT_SCORECARD = gql`
    mutation resetScorecardInCustomer($id: String! ) {
        resetScorecardInCustomer(id: $id){
            id
        }
    }
`;

export const GET_AVG_BY_SCORECARD = gql`
  query getAvgByScorecard($filter: MeetingAnalyticsInput) {
    getAvgByScorecard(filter: $filter){
      id
      score
      byUser {
        host
        hostName
        score
      }   
    }
  }
`;

export const GET_ALL_DEALNAMES = gql`
 query allOpportunities {
  allOpportunities {
        id
        name
    }
}
`;

export const SET_MEETING_RELATION = gql`
mutation setMeetingRelation($id:String!, $relation:RelationInput!) {
  setMeetingRelation(id:$id, relation:$relation){ id }
}`;


export const GET_SEARCH_COUNTS = gql`
    query getSearchCount($filterInput: FilterInput!) {
        getSearchCount(searchTerm:$filterInput) {
            searchCounts {
                totalMeeting
                totalMentions
                minDate
                week
                byTopic { topic count sum }
            }
            transcripts {
                id  meetingId 
                transcripts {  name text  subTopics  topics trackers
                    highlights {  text, topics, subTopics, trackers, name, title }
                }
            }
        }
    }
`;

export const GET_GRAPH_DATA_SEARCH_DEALS_WITH_CONFIG = gql`
    query getGraphDataSearchDealsWithConfig($searchTerm: FilterInput!, $args:ResultSetConfig) {
        searchDealsWithConfig(searchTerm: $searchTerm, args: $args) {
            totalCount
            searchResults {
                searchCountResult {
                    totalMeeting
                    totalMentions
                    week
                    minDate
                    byTopic { topic count sum }
                }
            }
        }
    }
`;

export const APPLY_FILTER_MEETING_ID_SEARCH_COUNTS_WITH_CONFIG = gql`
    query searchDealsMeetingIdSearchCountsWithConfig($searchTerm: FilterInput!, $args:ResultSetConfig) {
        searchDealsWithConfig(searchTerm: $searchTerm, args: $args) {
            totalCount
            searchResults {
                searchResult {
                    transcripts { id text topics trackers name }
                    meeting {
                        hostName
                        id
                        hostName
                        owner
                        title
                        date
                        duration
                        status
                        callType
                        transcriptionLanguage
                        callDisposition
                        users { firstName lastName }
                        teams { name }  
                        attendees { email  name role  status}
                        manager {name email id}
                        analytics {  lead_score { name score explanation }
                        tl_ratio sentiment patience longest_story_snippet { length } longest_monologue_snippet { length } }
                    }
                    opportunityName
                    providerOpportunityId
                    contactName
                    providerContactId
                    amount
                    closeDate
                    stageName
                    accountName
                    accountId
                    leadName
                    providerLeadId
                    leadCustomer
                }
            }
        }
    }
`;

export const APPLY_FILTER_MEETING_ID_SEARCH_COUNTS = gql`
    query searchDealsMeetingIdSearchCounts($searchTerm: FilterInput!) {
        searchDeals(searchTerm: $searchTerm) {
            searchResult {
                transcripts { id,text topics trackers name }
                meeting {
                    hostName
                    id
                    hostName
                    owner
                    title
                    date
                    duration
                    status
                    callType
                    transcriptionLanguage
                    callDisposition
                    users { firstName lastName }
                    teams { name }  
                    attendees { email  name role  status}
                    analytics { lead_score { name score explanation }
                    tl_ratio sentiment patience longest_story_snippet { length } longest_monologue_snippet { length } }
                }
                opportunityName
                contactName
                amount
                closeDate
                stageName
                accountName
                leadName
                leadCustomer
            }
            searchCountResult {
                totalMeeting
                totalMentions
                week
                minDate
                byTopic { topic count sum }
            }
        }
    }
`;

export const UPDATE_PROVIDER = gql`
    mutation updateProvider($userId: String!, $provider: ProviderInput) {
        updateProvider(userId:$userId, provider: $provider) {
            ...User
    }
  }
  ${userFragments.user}
`;

export const GET_ALL_EMAILS = gql`
 query getAllEmails($opportunityId: String!) {
  getAllEmails(opportunityId: $opportunityId) {
    id ,
    customerId,
    opportunityId
    contactId,
    contactEmail,
    provider,
    emailThread {from, to,subject, body,date},
    createdAt,
    updatedAt,
    owner {id, email, firstName, lastName}
    }}`;

export const GET_MY_ALERTS = gql`
  query getMyAlerts($userId: String!) {
    myAlerts(userId: $userId) {
        id
        name
        objectId
        objectType
        state
        triggerOn
        triggerAt
        createdAt
        updatedAt
    }
}`;

export const GET_CHATROOM_BYYNAME = gql`
  query chatRoomsByName($room: String!) {
    chatRoomsByName(room: $room) {
      id
      roomName
      customerId
      userId
      participant
      participantName
      privacy
      token
      createdAt
    }
  }
`;

export const GET_CHATROOM_PARTICIPANT = gql`
  query chatRoomByParticipant($room: String!,$participant: String!) {
    chatRoomByParticipant(room: $room,participant: $participant) {
      id
      roomName
      customerId
      userId
      participant
      participantName
      privacy
      token
      createdAt
      user { firstName lastName email}
    }
  }
`;
export const GET_CHATROOMS_PARTICIPANT = gql`
  query chatRoomsByParticipant($participant: String!) {
    chatRoomsByParticipant(participant: $participant) {
      id
      roomName
      customerId
      userId
      participant
      participantName
      privacy
      token
      createdAt
    }
  }
`;
export const POST_CHATROOM_PARTICIPANT = gql`
  mutation createChatRoom($chatRoom: ChatRoomInput!) {
    createChatRoom(chatRoom: $chatRoom) {
      id
      roomName
      customerId
      userId
      participant
      participantName
      privacy
      token
      createdAt
   }
  }
`;

const folowupFragments = {
    followup: gql`
  fragment FollowupInfo on Followup {
    id
    sharedLinkId
    followUpDate
    dealLinkId
    message
    subject
    customerId
    sharedWith
    attachments {
      id
      uuid
      url
      name
      fileType
      size
    }
    topics
    moments {marker, description}
    userId
    user { firstName lastName email}
    createdAt
    password
  }
`,
};

export const GET_FOLLOWUPS_BYCUSTOMER = gql`
  query followups($customerId: String!) {
    followups(customerId: $customerId) {
     ...FollowupInfo
  }}
  ${folowupFragments.followup}
`;

export const GET_FOLLOWUP = gql`
  query followup($id: String!) {
    followup(id: $id) {
    ...FollowupInfo
  }}
  ${folowupFragments.followup}
`;

export const GET_MYFOLLOWUPS = gql`
  query myFollowups($userId: String!) {
    myFollowups(userId: $userId) {
      ...FollowupInfo
    }}
    ${folowupFragments.followup}
  `;
export const GET_FOLLOWUPS_DEAL = gql`
  query followupsByDeal($dealLinkId: String!) {
    followupsByDeal(dealLinkId: $dealLinkId) {
      ...FollowupInfo
  }}
  ${folowupFragments.followup}
`;
export const GET_FOLLOWUPS_SHAREDLINK = gql`
  query followupBySharedLinkId($sharedLinkId: String!,$password:String!) {
    followupBySharedLinkId(sharedLinkId: $sharedLinkId,password:$password) {
      ...FollowupInfo
  }}
  ${folowupFragments.followup}
`;

export const EDIT_FOLLOWUP = gql`
  mutation createFollowup($followup: FollowupInput!) {
    createFollowup(followup: $followup) {
      ...FollowupInfo
    }}
    ${folowupFragments.followup}
  `;

const chatFragments = {
    chat: gql`
    fragment ChatInfo on Chat {
      id
      transcriptId
      author
      meetingId
      time
      text
      rating
      isPrivate
      followupId
    }
  `,
};
export const ADD_CHAT = gql`
  mutation CreateChat($chat: ChatInput!) {
    createChat(chat: $chat) {
      ...ChatInfo
   }
  }
  ${chatFragments.chat}
`;

export const EDIT_CHAT = gql`
  mutation EditChat($id: String!, $chat: ChatInput!) {
    editChat(id: $id, chat: $chat) {
      ...ChatInfo
   }
  }
  ${chatFragments.chat}
`;

export const GET_CHATSBY_FOLLOWUP = gql`
  query chatsByFollowupId($followupId: String!) {
    chatsByFollowupId(followupId: $followupId) {
      ...ChatInfo
    }
  }
  ${chatFragments.chat}
`;

export const GET_CHATS_FOR_MEETING= gql`
  query chatsByMeetingId($meetingId: String!) {
    chatsByMeetingId(meetingId: $meetingId) {
      id, chats {
        ...ChatInfo
      }
    }
  }
  ${chatFragments.chat}
`;

export const GET_CHATS_TRANSCRIPT = gql`
  query chats($transcriptId: String!) {
    chats(transcriptId: $transcriptId) {
      ...ChatInfo
    }
  }
  ${chatFragments.chat}
`;

export const GET_MY_BOOKMARKS = gql`
  query myBookmarks($userId: String!) {
    myBookmarks(userId: $userId) {
      ...BookmarkType
        }
    }
 ${bookmarkFragment.bookmark}`;

export const HAS_FOLLOWUP_BY_MEETING_ID = gql`
 query hasFollowupByMeetingId($meetingId: String!) {
    hasFollowupByMeetingId(meetingId: $meetingId) {
        hasFollowup
    }
 }
`;

export const GET_CONVERSATIONS_FOR_USER = gql`
  query conversations($ownerEmail: String!){
    conversations(ownerEmail:$ownerEmail) {
        ...Conversation
    }
}`;

export const GET_ALL_CONVERSATIONS = gql`
  query allConversations {
    allConversations {
        ...Conversation
    }
}`;

export const GET_ALL_CONVERSATIONS_BY_TICKET = gql`
  query allConversationByTicket($ticketId:String!) {
    allConversationByTicket(ticketId:$ticketId) {
        ...Conversation
    }
}`;

export const GET_ALL_CONVERSATIONS_BY_ID = gql`
  query conversation($id:String!) {
    conversation(id:$id) {
        ...Conversation
    }
}`;

export const CREATE_CONVERSATION = gql`
    mutation createConversation($conversation: ConversationInput!) {
        createConversation(conversation: $conversation) {
            ...Conversation
        }
    }
    ${conversationFragments.conversation}
`;

export const EDIT_CONVERSATION = gql`
    mutation editConversation($id: String!, $conversation: ConversationInput) {
        editConversation(id: $id, conversation: $conversation) {
            ...Conversation
        }
    }
    ${conversationFragments.conversation}
`;


export const DELETE_CONVERSATION = gql`
    mutation deleteConversation($id: String!) {
        deleteConversation(id: $id) {
            ok
        }
    }
    ${conversationFragments.conversation}
`;


export const GET_TICKETS_FOR_USER = gql`
  query tickets($ownerEmail: String!){
    tickets(ownerEmail:$ownerEmail) {
        ...Ticket
    }
}`;

export const GET_ALL_TICKETS_FOR_USER = gql`
  query allTickets{
    allTickets {
        ...Ticket
    }
}`;

export const GET_TICKET_BY_ID = gql`
  query ticket($id: String!){
    ticket(id:$id) {
        ...Ticket
    }
}`;

export const GET_ALL_TICKETS_BY_CONTACT_ID = gql`
  query allByContactId($contactId: String!){
    allByContactId(contactId:$contactId) {
        ...Ticket
    }
}`;


export const CREATE_TICKET = gql`
    mutation createTicket($ticket: TicketInput!) {
        createTicket(ticket: $ticket) {
           ...Ticket
        }
    }
    ${ticketFragments.ticket}
`;

export const EDIT_TICKET = gql`
    mutation editTicket($id: String!, $ticket: TicketInput!) {
        editTicket(id: $id, ticket: $ticket) {
            ...Ticket
        }
    }
    ${ticketFragments.ticket}
`;

export const DELETE_TICKET = gql`
    mutation deleteTicket($id: String!) {
        deleteTicket(id: $id) {
            ok
        }
    }
    ${ticketFragments.ticket}
`;

export const GET_USER_ANSWERS = gql`
  query getUserAnswers($userId: String, $customerId: String){
    getUserAnswers(userId:$userId, customerId:$customerId) {
        id
        title
        sessionId
        messages {
            messageId
            query
            response
            timestamp
        }
    }
}`;

// Query to get all system templates
export const GET_SYSTEM_GRID_TEMPLATES = gql`
  query systemGridTemplates {
    systemGridTemplates {
      id
      templateName
      description
      type
      category
      dataSource
      isActive
      columns {
        id
        headerName
        prompt
      }
    }
  }
`;

// Query to get a specific template by name
export const GET_GRID_TEMPLATE_BY_NAME = gql`
  query gridTemplateByName($templateName: String!, $customerId: String) {
    gridTemplateByName(templateName: $templateName, customerId: $customerId) {
      id
      templateName
      description
      type
      customerId
      createdBy
      category
      dataSource
      isActive
      columns {
        id
        headerName
        prompt
      }
    }
  }
`;

// Query to get all templates (both system and custom)
export const GET_GRID_TEMPLATES = gql`
  query gridTemplates($customerId: String) {
    gridTemplates(customerId: $customerId) {
      id
      templateName
      description
      type
      customerId
      createdBy
      category
      dataSource
      isActive
      columns {
        id
        headerName
        prompt
      }
    }
  }
`;

// Query to get a specific template by ID
export const GET_GRID_TEMPLATE = gql`
  query gridTemplate($id: ID!) {
    gridTemplate(id: $id) {
      id
      templateName
      description
      type
      customerId
      createdBy
      category
      dataSource
      isActive
      columns {
        id
        headerName
        prompt
      }
    }
  }
`;

export const GET_GRID_REPORTS = gql`
  query gridReports($customerId: String) {
    gridReports(customerId: $customerId) {
      id
      reportName
      description
      templateId
      templateName
      customerId
      createdBy
      userId
      createdAt
      updatedAt
      columns {
        id
        headerName
        field
        width
        filter
        prompt
      }
      data {
        id
        fields {
          key
          value
        }
      }
    }
  }
`;

export const GET_GRID_REPORTS_COUNT = gql`
  query getGridReportsCount($customerId: String!, $startDate: String!, $endDate: String!) {
    getGridReportsCount(customerId: $customerId, startDate: $startDate, endDate: $endDate)
  }
`;

export const GET_USER_DAILY_QUESTION_COUNT = gql`
        query getUserDailyQuestionCount($userId: String) {
            getUserDailyQuestionCount(userId: $userId)
        }
    `;