type FilterData = {
    label: string;
    value: any;
    metrics?: boolean;
};

export const mapFilterValues = (data) => {
    const mapLables = {
        representative: "REP",
        team: "TEAM",
        manager: "MANAGER",
        meetingDate: "MEETING DATE RANGE",
        meetingDuration: "MEETING DURATION",
        duration: "MEETING DURATION",
        meetingStage: "DEAL STAGE AT TIME OF MEETING",
        callCategory: "CALL CATEGORY",
        callType: "CALL TYPE",
        callScope: "CALL SCOPE",
        participants: "PARTICIPANTS",
        talkListenRatio: "TALK LISTEN RATIO",
        longestMonologue: "LONGEST MONOLOGUE",
        longestStory: "LONGEST STORY",
        interactivity: "INTERACTIVITY",
        patience: "PATIENCE",
        hostQuestions: "NUMBER OF HOST QUESTIONS",
        prospectQuestions: "NUMBER OF PROSPECT QUESTIONS",
        rateofHostQuestions: "RATE OF HOST QUESTIONS",
        rateofProspectQuestions: "RATE OF PROSPECT QUESTIONS",
        topics: "TOPICS",
        trackers: "TRACKERS",
        CRM: "CRM",
        currentStage: "STAGE",
        amount: "AMOUNT",
        closeDate: "CLOSE DATE",
        owner: "OWNER",
        accountName: "ACCOUNT NAME",
        lastUpdatedDate: "LAST UPDATED DATE",
        leadSources: "LEAD SOURCES",
        callDisposition: "CALL DISPOSITIONS",
        callDirection: "CALL DIRECTION",
        providers: "MEETING SOURCES",
        undefined: "PARAM"
    };
    const mapedData: FilterData[] = [];
    if (data instanceof Object) {
        const appliedFiltersKeys = Object.keys(data);
        const savedKeys: string[] = [];
        appliedFiltersKeys.forEach((filter) => {
            if (data[filter] instanceof Object && data[filter].label) {
                if (data[filter].label) {
                    mapedData.push({
                        label: mapLables[filter],
                        value: data[filter].label,
                    });
                }
            } else if (data[filter] instanceof Object) {
                const dataValues = Object.keys(data[filter]);
                const value = dataValues.map((choices) => data[filter][choices]?.label).join(", ");
                if (value) {
                    mapedData.push({label: mapLables[filter], value});
                }
            } else if (typeof data[filter] === "number") {
                const key = filter.substring(0, filter.length - 3);
                if (!savedKeys.includes(key)) {
                    mapedData.push({
                        label: mapLables[key],
                        metrics: true,
                        value: {min: data[`${key}Min`], max: data[`${key}Max`]},
                    });
                    savedKeys.push(key);
                }
            }
        });
    }
    return mapedData;
};